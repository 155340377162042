/* testimonials on for candidates page */
.reviews {
  padding-top: ac(140px, 80px);
  padding-bottom: ac(160px, 86px);
  overflow: hidden;

  &-wrap {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    @media (max-width: 1240px) {
      flex-direction: column;
      justify-content: flex-start;
      gap: ac(38px, 28px);
    }
  }
  &-text {
    max-width: ac(394px, 380px);
    @media (max-width: 1240px) {
      max-width: 820px;
    }
  }
  &-title {
    font-weight: 600;
    font-size: ac(56px, 36px);
    line-height: 1.12;
    color: #fff;
    margin-bottom: ac(24px, 18px);
    span {
      font-family: 'Ysabeau', sans-serif;
      font-style: italic;
      font-weight: 700;
      font-size: ac(60px, 38px);
      background: linear-gradient(
        46deg,
        rgba(145, 243, 218, 0.96) 0%,
        #f4eae5 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &-descr {
    font-weight: 400;
    font-size: ac(20px, 18px);
    line-height: 1.44;
    color: #b0d8ce;
    &:not(:last-child) {
      margin-bottom: ac(16px, 10px);
    }
  }
  &-swiper-wr {
    display: flex;
    align-items: center;
    gap: ac(20px, 16px);
    @media (max-width: 720px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 46px;
    }
  }
  &-slider {
    max-width: ac(686px, 545px);
    max-height: 472px;
    overflow: visible;
    margin: 0;
    @media (max-width: 1240px) {
      max-height: ac(434px, 350px, 500, 1240);
    }
    @media (max-width: 640px) {
      max-height: 430px;
    }
    @media (max-width: 440px) {
      max-height: 480px;
    }
    &__arrows {
      flex-direction: column;
      margin: 0;
      gap: ac(40px, 38px);
      width: 56px;
      @media (max-width: 720px) {
        width: auto;
        flex-direction: row;
        gap: 16px;
      }
      .swiper-button-next,
      .swiper-button-prev {
        transform: rotate(90deg);
        @media (max-width: 720px) {
          transform: rotate(0);
        }
      }
    }
  }
  &-card {
    padding: ac(40px, 20px);
    padding-bottom: ac(32px, 18px);
    border: 2px solid #e6f8e9;
    border-radius: 20px;
    max-width: ac(686px, 545px);
    max-height: 472px;
    background: linear-gradient(
      46deg,
      rgba(174, 214, 204, 0.96) 0%,
      #eeeceb 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1240px) {
      max-height: ac(434px, 350px, 500, 1240);
    }
    @media (max-width: 640px) {
      max-height: 430px;
    }
    @media (max-width: 440px) {
      max-height: 480px;
    }
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0);
      position: absolute;
      inset: 0;
      transition: 0.3s;
      z-index: -1;
    }
    &.swiper-slide-next {
      border-color: transparent;
      &::before {
        background-color: rgba(106, 106, 106, 0.353);
      }
    }
    &.swiper-slide-next + .swiper-slide {
      border-color: transparent;
      &::before {
        background-color: rgba(41, 41, 41, 0.661);
      }
    }
    &__descr {
      font-weight: 400;
      font-size: ac(24px, 18px, 640);
      line-height: 1.48em;
      color: rgba(5, 5, 5, 0.9);
      margin-bottom: ac(32px, 20px);
      max-height: calc(1.48em * 8);
      overflow-y: auto;
      @media (max-width: 640px) {
        max-height: calc(1.48em * 12);
      }
      p {
        &:not(:last-child) {
          margin-bottom: ac(16px, 10px);
        }
      }
    }
    &__user {
      display: flex;
      align-items: center;
      gap: ac(22px, 14px);
      @media (max-width: 440px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1px;
      }
      &-image {
        &__wrap {
          --size: ac(80px, 61px, 440);
          background: linear-gradient(
            46deg,
            rgba(145, 243, 218, 0.96) 0%,
            #f4eae5 100%
          );
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 100%;
          width: var(--size);
          height: var(--size);
        }
        border-radius: 100%;
        --size: ac(72px, 53px, 440);
        width: var(--size);
        height: var(--size);
        border: 1px solid #fff;
      }
      &-text {
      }
      &-name {
        font-weight: 700;
        font-size: ac(28px, 20px);
        line-height: 1.48;
        color: var(--cl-black);
        margin-bottom: ac(4px, 1px);
      }
      &-position {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.48;
        color: rgba(18, 18, 18, 0.8);
      }
    }
  }
}
