.our-expertise {
    z-index: 2;

    &__wrapper {
        padding-top: ac(120px, 40px);
        padding-bottom: ac(200px, 120px);
        z-index: 4;
        position: relative;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 920px) {
            gap: 24px;
            flex-direction: column;
        }
    }

    &__title {
        h2 {
            span {
                font-weight: 700;
                font-size: ac(60px, 44px);
            }
        }
    }

    &__list {
        padding-top: ac(80px, 32px);
    }

    &__button {
        z-index: 6;
        .btn__small {
            height: 40px;
            background: var(--cl-black);
            background: transparent;

            i {
                color: var(--gradient);
            }

            &:after {
                background: inherit;
            }

            &:hover {
                background: var(--gradient);

                i {
                    color: var(--cl-black);
                }
            }
        }
    }

    &__item {
        position: relative;
        max-width: 495px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: ac(40px, 24px) ac(40px, 28px);
        gap: 24px;
        border-radius: 16px;
        border: 1px solid var(--cl-aqua);
        overflow: hidden;
        z-index: 6;
        min-height: 372px;
        height: auto;
        transition: .3s ease-in;
        @media (max-width: 640px) {
            max-width: ac(495px, 318px, 375, 640);
            min-height: 380px;
        }
        @media (max-width: 374px) {
            max-width: ac(318px, 280px, 320, 375);
        }
        &:before {
            content: "";
            position: absolute;
            inset: 0;
            z-index: 3;
            background: linear-gradient(293.19deg, rgba(5, 5, 5, 0.6) 0%, #050505 100%);
            transition: .3s ease;
        }

        &:after {
            content: "";
            position: absolute;
            inset: 0;
            z-index: 2;
            background: #91F3DA;
            mix-blend-mode: color;
        }

        &:hover {
            &:before {
                background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
            }

            .our-expertise__item-title {
                color: var(--cl-black);
            }

            .our-expertise__item-border {
                background: var(--cl-black);
            }

            .our-expertise__item-content {
                color: var(--cl-black);
            }

        }

        &-title {
            position: relative;
            color: var(--cl-white);
            z-index: 5;
            transition: .3s ease;
        }

        &-border {
            position: relative;
            width: 100%;
            height: 1px;
            background: var(--cl-aqua);
            transition: .3s ease;

            z-index: 5;
        }

        &-content {
            color: var(--cl-aqua);
            position: relative;
            z-index: 5;
            transition: .3s ease;
            font-size: ac(22px, 17px);
            line-height: 1.44;
            max-height: 380px;
            overflow: auto;
            @media (max-width: 640px) {
                max-height: 296px;
            }
        }
    }

    &__bg {
        position: absolute;
        inset: 0;
        z-index: 2;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__buttons {
        position: absolute;
        right: 0;
        bottom: ac(80px, 40px);

        @media (max-width: 1200px) {
            left: 0;
            right: auto;
        }
    }

    &__scrollbar {
        display: flex;
        align-items: center;

        background: #FFFFFF4D;

        &.swiper-scrollbar-horizontal {
            position: absolute;
            bottom: calc(80px + (56px / 2));

            width: 82.35%;
            height: 3px;
        }

        .swiper-scrollbar-drag {
            height: 6px;
            background: var(--cl-aqua);
        }
        
        @media (max-width: 1200px) {
            display: none;
        }
    }

    &__slider {
        .swiper {
            overflow: visible;
            max-width: 495px;
            margin: 0;
            @media (max-width: 640px) {
                max-width: ac(495px, 318px, 375, 640);
            }
            @media (max-width: 374px) {
                max-width: ac(318px, 280px, 320, 375);
            }
        }
    }
}