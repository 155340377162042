.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-green) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-black);

  color: var(--cl-white);
  font-family: var(--font-fourth);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-black);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-green);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.cont {
  margin: 0 auto;
  max-width: 1224px;
  width: perc(1234);

  @mixin max-lg {
    width: 89.33%;
  }
}

section {
  position: relative;
}

.sections-container {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  overflow: hidden;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 148px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }

  @mixin max-sm {
    width: 159px;
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;
    word-wrap: break-word;
    word-break: break-word;
    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .not-found__content {
    margin-top: 0;
  }
  h1 {
    font-size: ac(92px, 52px);
    line-height: 1.3;
    margin-bottom: ac(10px, 6px);
  }
  p {
    margin-bottom: ac(24px, 20px);
  }
  h1,
  p {
    padding: 0;
    text-align: center;
  }

  a {
    display: flex;
  }
}

.social-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  border-radius: 6px;
  border: 2px solid var(--cl-slate);
  overflow: hidden;
  background: var(--cl-slate);
  color: var(--cl-orange);
  transition: color 0.3s ease;
  width: 32px;
  height: 32px;
  position: relative;

  i {
    position: relative;
    z-index: 5;
  }

  &:before {
    position: absolute;
    inset: 0;
    background: var(--cl-orange);
    content: '';
    transform: translateY(100%);
    transition: transform 0.3s ease;
  }

  /*i.icon-youtube {
    margin-bottom: -6px;
  }*/

  &:hover {
    color: var(--cl-grey);

    &:before {
      transform: translateY(0);
    }
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.holding {
  overflow: hidden;
  position: relative;
  z-index: 5;

  &__bg {
    @mixin aspect-ratio 2288, 1152;
    width: 150%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateY(-60%)translateX(-12%);
    max-width: 2300px;

    @mixin max-sm {
      display: none;
    }
  }

  &__bg-container {
    @mixin aspect-ratio-block 799, 498;
    width: 62.62%;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(30%) !important;
    overflow: visible !important;
    z-index: -1;
    min-width: 420px;

    @mixin max-sm {
      opacity: .8;
    }
  }

  &__bg-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;

    .circle {
      @mixin aspect-ratio 1, 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      filter: blur(10px);
    }

    &:nth-child(1) {
      z-index: 2;

      @mixin sm {
        top: -35%;
      }
    }

    &:nth-child(2) {
      left: auto;
      right: 0;

      @mixin sm {
        top: 10%;
      }
    }
  }

  &__bg-page {
    position: absolute;
    inset: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    padding-top: ac(120px, 64px);
    padding-bottom: ac(120px, 64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    z-index: 10;
    min-height: 100vh;
  }

  &__logo {
    @mixin aspect-ratio 514.26, 555.44;
    width: 100%;
    max-width: ac(220px, 130px);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ac(-34px, 0px);

    img {
      object-fit: contain;
      object-position: left;
    }

    &:not(:last-child) {
      margin-bottom: ac(40px, 30px);
    }
  }

  &__text {
    width: 100%;
    max-width: ac(620px, 450px);

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }

    .underline {
      text-underline-offset: ac(10px, 8px);
    }
  }

  &__socials {

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__link {
    display: inline-flex;
    font-weight: 500;
    font-size: ac(26px, 18px);
    line-height: normal;
    /*display: flex;*/
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 5;
    transition: color .31s ease;
    color: var(--cl-green);

    &:before {
      bottom: 0;
      left: 0;
      right: 0;
      content: '';
      height: 2px;
      background: var(--cl-green);
      transition: background-color 0.4s ease, height 0.2s ease, opacity .3s ease;
      position: absolute;
      z-index: -1;
    }

    &:hover {
      &:before {
        transition: background-color 0.3s ease, height 0.3s ease;
        height: 50%;
        background-color: var(--cl-white);
        opacity: .3;
      }
    }

  }
}

.image-aspect-ratio {
  position: relative;
  overflow: hidden;
  mask-image: radial-gradient(white, black);

  &::before {
    content: '';
    display: block;
    padding-bottom: calc(min(100% * (var(--arh) / var(--arw))));
  }

  img,
  video {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    top: 0;
    left: 0;
  }
}

.border-gradient-block {
  position: relative;
  z-index: 1;
  &:after {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background: var(--gradient);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    border-radius: inherit;
  }
}

.for-candidates-container {
  position: relative;
  background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
  &-bg {
    position: absolute;
    inset: 0;
    z-index: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-decor {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    max-width: ac(410px, 164px, 320, 992);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .5;
    }
  }
}
