.first-section {
  position: relative;
  z-index: 10;
  overflow: hidden;
  padding-top: ac(200px, 162px);
  padding-bottom: ac(143px, 48px);

  &__bg-image {
    position: absolute;
    inset: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      280.11deg,
      rgba(18, 18, 18, 0.4) 7.57%,
      #121212 100%
    );
    z-index: 4;
  }

  &__wrapper {
    position: relative;
    z-index: 5;
  }
  &__text {
    max-width: ac(600px, 480px);
  }
  &__title {
    h1 {
      span {
        font-weight: 700;
        font-size: ac(94px, 54px);
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__content {
    /* width: 51.02%; */
    padding-top: ac(24px, 20px);
    font-weight: 600;
    line-height: 156%;

    p {
      color: var(--cl-aqua);
      line-height: 1.5;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__button {
    margin-top: 48px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;

    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__line {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: 1;
  }

  &.for-candidates {
    padding-top: ac(236px, 160px);
    padding-bottom: ac(156px, 82px);
    &:before {
      display: none;
    }
    .first-section {
      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        @media (max-width: 780px) {
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 26px;
        }
      }
      &__text {
        max-width: ac(704px, 410px);
        @media (max-width: 780px) {
          max-width: 768px;
        }
      }
      &__title {
        font-size: ac(68px, 42px);
        span {
          font-size: ac(80px, 48px);
        }
      }
      &__image {
        margin-right: ac(133px, 32px);
        max-width: ac(200px, 130px, 640);
        @media (max-width: 640px) {
          max-width: ac(130px, 84px, 320, 640);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.clients-page {
    .cont {
      max-width: 1232px;
      width: perc(1244);
    }
    &.first-section {
      padding: 0;

      &:before {
        content: '';
        position: absolute;
        inset: 0;
        background: #B0D8CE;

        mix-blend-mode: color;
        z-index: 4;
      }

      &:after {
        content: "";
        position: absolute;
        inset: 0;
        background: #00000099;

        z-index: 4;
      }
    }

    .first-section__wrapper {
      padding-top: ac(240px, 162px);
      padding-bottom: ac(108px, 80px);
    }

    .first-section__text {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      min-width: 100%;

      gap: ac(56px, 42px);
    }

    .first-section__title {
      width: 100%;
      max-width: ac(704px, 478px);

      &.heading-1 {
        font-size: ac(68px, 42px, 640);
        span {
          font-size: ac(80px, 44px, 640);
        }
      }
    }

    .first-section__content {
      margin-left: auto;
      padding: 0;
      font-weight: 400;
      font-size: ac(22px, 20px);
      width: 44.04%;

      p {
        color: var(--cl-white);
      }

      @media (max-width: 1200px) {
        margin: 0;
        width: 100%;
        font-weight: 600;
      }
    }

    .first-section__border {
      background: var(--cl-aqua);
      height: 4px;
      width: 100%;
    }
  }
}
