.cookies {
    position: relative;
    overflow: hidden;
    z-index: 10;

    &:before {
        position: absolute;
        inset: 0;
        background: linear-gradient(180deg, rgba(5, 5, 5, 0) 49.97%, rgba(5, 5, 5, 0.8) 100%);
        z-index: 3;
    }

    p {
        font-size: ac(20px, 16px);
    }

    h1 {
        font-size: ac(40px, 32px);
        color: var(--cl-aqua);
        font-weight: 700;
    }

    h2 {
        font-size: ac(32px, 28px);
        color: var(--cl-aqua);
        font-weight: 600;

        margin-top: ac(40px, 20px);
    }

    h3 {
        font-size: ac(28px, 24px);
        color: var(--cl-aqua);
        font-weight: 500;

        &:nth-of-type(2) {
            margin-top: 40px;
        }

        &:nth-of-type(4) {
            margin-top: 40px;
        }
    }

    .text-red-400 {
        color: var(--cl-aqua);
    }


    &__bg {
        position: absolute;
        inset: 0;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}