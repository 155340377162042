.choose-section {
    overflow: hidden;
    z-index: 10;
    &__wrapper {
        padding-top: ac(108px, 38px);
        padding-bottom: ac(108px, 40px);
    }

    &__title {
        text-align: center;
        span {
            font-size: ac(60px, 48px);
        }
    }
    &__slider {
        @media (max-width: 992px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .choose-section__buttons {
            margin-top: ac(48px, 32px, 768, 992);
            display: none;
            @media (max-width: 992px) {
                display: flex;
            }
        }
    }
    .swiper {
        margin: 0;
        overflow: visible;
        @media (max-width: 992px) {
            max-width: ac(392px, 320px, 400, 992);
        }
        @media (max-width: 374px) {
            max-width: ac(320px, 285px, 320, 374);
        }
    }
    /* swiper-wrapper */
    &__list {
        margin-top: ac(64px, 48px);
        display: flex;
        justify-content: center;
        gap: ac(24px, 12px, 1024);
        flex-wrap: wrap;
        @media (max-width: 992px) {
            flex-wrap: nowrap;
            justify-content: flex-start;
            gap: 0;
        }
    }

    &__item {
        position: relative;
        padding: ac(50px, 36px) ac(24px, 20px);
        border: 1px solid rgba(176, 216, 206, 0.5);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        max-width: ac(392px, 281px, 1024);
        min-height: 202px;
        height: auto;
        transition: .3s all;
        @media (max-width: 992px) {
            max-width: ac(392px, 320px, 400, 992);
        }
        @media (max-width: 374px) {
            max-width: ac(320px, 285px, 320, 374);
        }
        &:hover {
            background: var(--gradient);

            .choose-section__content {
                color: var(--cl-black);
            }
        }
    }

    &__icon {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #7DCB87;
        border-radius: 50%;
        box-shadow: 0px 4px 4px 0px #00000040;

        &:before {
            content: '\e907';
            display: block;
            font-family: 'icomoon' !important;
            font-size: 18px;
            color: var(--cl-black);
        }
    }

    &__content {
        color: var(--cl-aqua);
        font-weight: 500;
        font-size: ac(24px, 18px);
        transition: .3s;
    }
}