.who-we-section {
    background: linear-gradient(29.92deg, #AED6CC -20.99%, #EEECEB 87.62%);
    position: relative;
    z-index: 5;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        gap: ac(56px, 32px);

        padding-top: ac(108px, 24px);
        padding-bottom: ac(108px, 40px);

        z-index: 10;

        @media (max-width: 1200px) {
            flex-direction: column;
        }
    }

    &__content {
        color: var(--cl-black);

        p {
            font-size: ac(20px, 17px);
            line-height: 144%;

            &:first-of-type {
                padding-top: 24px;
            }
        }
    }

    &__subtitle {
        padding-top: ac(24px, 18px);
        font-weight: 500;
        font-size: ac(26px, 20px);
        line-height: 140%;
    }

    &__image {
        position: relative;
        min-width: 46.41%;
        z-index: 5;
        border-radius: ac(20px, 14px);
        overflow: hidden;

        &:before {
            content: '';
            position: absolute;
            inset: 0;
            background: #121212B2;
            z-index: 2;
        }

        @media (max-width: 450px) {
            width: 100%;
            max-height: 245px;
        }

        @media (max-width: 1199px) {
            aspect-ratio: 2 / 1;
        }

        &-logo {
            position: absolute;

            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transition: .3s ease;
            z-index: 3;


            svg {
                @media (max-width: 560px) {
                    width: 120px;
                    height: 129px;
                }
            }
        }
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;

        z-index: -1;
        mix-blend-mode: soft-light;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    }
}