.footer {
  background-color: #121212;
  padding-top: ac(80px, 24px);
  padding-bottom: ac(56px, 24px);
  &-wrap {
    display: flex;
    gap: ac(24px, 12px);
    flex-wrap: wrap;
    @media (max-width: 1160px) {
      justify-content: center;
    }
  }

  /* w100% */
  &-main {
    padding: ac(40px, 24px);
    border-radius: 16px;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid #91F3DA;
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 900px) {
      flex-direction: column;
    }
    @media (max-width: 574px) {
      padding: ac(40px, 18px);
    }
    &__wrap {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 900px) {
        flex-wrap: wrap;
        gap: 32px;
      }
    }
  }
  &-logo {
    max-width: ac(224px, 130px);
    width: 100%;
    padding-right: ac(48px, 24px);
    border-right: 1px solid var(--cl-aqua);
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 900px) {
      border-right: 0;
      border-bottom: 1px solid var(--cl-aqua);
      padding: 0;
      padding-bottom: 24px;
      margin-bottom: 16px;
      width: 100%;
      max-width: none;
      justify-content: flex-start;
    }
    &:hover {
      img {
        transform: scale(1.03);
      }
    }
    img {
      width: 100%;
      height: auto;
      transition: .3s all;
      @media (max-width: 900px) {
        max-width: ac(224px, 120px);
      }
    }
  }
  &-text {
    max-width: ac(452px, 400px);
    margin-left: ac(48px, 24px);
    @media (max-width: 900px) {
      margin-left: 0;
      max-width: ac(400px, 350px, 720, 900);
    }
    @media (max-width: 720px) {
      max-width: none;
      width: 100%;
    }
    &__title {
      font-weight: 600;
      font-size: ac(28px, 26px);
      line-height: 1.32;
      letter-spacing: 0.28px;
      color: #fff;
      margin-bottom: 8px;
    }
    &__descr {
      font-weight: 400;
      font-size: ac(18px, 17px);
      line-height: 1.56;
      color: var(--cl-aqua);
      margin-bottom: 24px;
      @media (max-width: 640px) {
        line-height: 1.44;
      }
    }
    &__btn {
      width: fit-content;
      @media (max-width: 576px) {
        width: 100%;
      }
    }
  }

  &-links {
    margin-left: ac(80px, 24px);
    max-width: ac(292px, 240px);
    width: 100%;
    @media (max-width: 900px) {
      margin-left: 0;
    }
    @media (max-width: 720px) {
      max-width: none;
      width: 100%;
    }
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.32;
      background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 24px;
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 100px;
      padding: ac(12px, 9px) ac(20px, 16px);
      width: 100%;
      max-width: 291px;
      font-weight: 800;
      font-size: ac(16px, 15px);
      border: 1px solid rgba(174, 214, 204, 0.96);
      line-height: 1.4;
      background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: .3s all;
      &:not(:last-child) {
        margin-bottom: 12px;
      }
      i {
        color: rgba(174, 214, 204, 0.96);
        font-size: ac(22px, 18px);
        transition: .3s all;
      }
      &:hover {
        opacity: .85;
        i {
          /* transform: translateX(5px); */
        }
      }
    }
    &__socials {
      margin-top: ac(40px, 28px);
      display: flex;
      align-items: center;
      span {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.32;
        background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-right: ac(24px, 12px);
      }
    }
    &__social {
      --size: ac(44px, 40px);
      width: var(--size);
      height: var(--size);
      border-radius: 100%;
      background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      &:not(:last-child) {
        margin-right: 12px;
      }
      i {
        color: var(--cl-black);
        font-size: ac(32px, 24px);
        transition: .3s all;
      }
      &:hover {
        i {
          transform: scale(1.1);
        }
      }
    }
  }

  &-location {
    padding: ac(32px, 24px) ac(40px, 20px, 992);
    min-height: ac(225px, 180px);
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid #91F3DA;
    border-radius: 16px;
    width: 32%;
    @media (max-width: 1160px) {
      width: 49%;
    }
    @media (max-width: 900px) {
      width: 100%;
    }
    &__title {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.32;
      background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: ac(24px, 12px);
    }
    &__address {
      font-weight: 400;
      font-size: ac(18px, 17px);
      line-height: 1.48em;
      min-height: calc(3 * 1.48em);
      color: #fff;
      margin-bottom: 24px;
      @media (max-width: 900px) {
        min-height: auto;
      }
    }
    &__links {
      display: flex;
      gap: ac(24px, 16px);
      flex-wrap: wrap;
    }
    &__link {
      display: flex;
      align-items: center;
      transition: .3s all;
      i {
        font-size: ac(24px, 20px);
        margin-right: ac(8px, 6px);
        width: 24px;
        &.icon-mail {
          font-size: ac(30px, 24px);
        }
      }
      span {
        font-weight: 500;
        font-size: ac(18px, 17px, 992);
        line-height: 1.48;
        text-decoration: underline;
        color: #fff;
        transition: .3s all;
      }

      &:hover {
        opacity: .85;
        span {
          text-decoration-color: transparent;
        }
      }
    }
  }

  &-bottom {
    background-color: var(--cl-black);
    padding: 24px 0;
    &__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap-reverse;
      gap: 16px;
    }

    &__copy {
      font-weight: 500;
      font-size: ac(14px, 12px);
      line-height: 1.48;
      color: #999;
    }
    &__links {
      display: flex;
      gap: ac(16px, 8px);
      flex-wrap: wrap;
      @media (max-width: 470px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 290px;
      }
    }
    &__link {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.48;
      text-decoration: underline;
      color: #b0d8ce;
      transition: .3s all;
      &:not(:last-child) {
        border-right: 1px solid rgba(176, 216, 206, 0.5);
        padding-right: ac(16px, 8px);
        @media (max-width: 576px) {
          border: none;
          padding: 0;
        }
      }
      &:hover {
        text-decoration-color: transparent;
      }

    }
  }
}