.not-found {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    min-width: 100vw;
    overflow: hidden;
    z-index: 5;
    padding: 0;
    margin: 0;

    &__title {
        font-size: ac(120px, 100px);
    }

    &:before {
        position: absolute;
        inset: 0;
        background: linear-gradient(180deg, rgba(5, 5, 5, 0) 49.97%, rgba(5, 5, 5, 0.8) 100%);
        z-index: 3;
    }

    &__content {
        margin-top: 27px;

        p {
            margin: 0;
        }
    }

    &__button {
        .btn {
            min-width: 200px;
        }
    }

    &__bg {
        position: absolute;
        inset: 0;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}