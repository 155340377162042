/* ----------------------- Common desktop header ----------------------- */
.header {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
  height: var(--header-height);
  border-bottom: 1px solid transparent;
  &.absolute {
    position: absolute;
  }
  &.active {
    background-color: var(--cl-black);
    border-color: #b0d8ce;
  }

  &.scrolled {
    background-color: var(--cl-black);
    border-color: #b0d8ce;
  }
  /* .cont {
    padding-right: ac(23px, 24px);
    padding-left: ac(35px, 24px);
    z-index: 3;
  } */

  &.fixed {
    position: fixed;
  }

  /* &::after {
    content: '';
    position: absolute;
    inset: 0;
    width: 100%;
    backdrop-filter: blur(12px);
    background: rgba(5, 61, 128, 0.5);
  } */

  /* hz */
  /* &:before {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background-color: transparent;
    opacity: 0;
    transition: all 0.25s ease;
  } */

  /* &.scrolled {
    &:not(.open-menu) {
      .logo {
        max-width: 190px;
        @mixin max-xs {
          max-width: 165px;
        }
      }
    }
  } */

  .logo {
    height: auto;
    width: 100%;
    max-width: 156px;
    transition: transform 0.25s ease, width 0.25s ease;
    z-index: 100;
    &:hover {
      transform: scale(1.04);
    }
    img {
      width: 100%;
      height: 100%;
    }
    @media (max-width: 640px) {
      max-width: 120px;
    }
  }

  /* &.scrolled {
    .logo {
      width: 90%;
    }
  } */

  &-bottom {
    @mixin mobile-menu-end-point {
      &:after {
        display: none;
      }
    }
  }

  /* base descktop navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* padding: ac(13px, 10px) ac(50px, 42px);
    @media (max-width: 768px) {
      padding: 6px 12px 6px 24px;
    } */
    &.active {
      margin-right: 11px;
      @media (pointer: coarse) {
        margin-right: 0;
      }
    }
  }

  .burger {
    &-wrap {
      display: none;
    }
  }

  /* list, menu */
  .navbar-nav {
    display: flex;
    flex-grow: 1;
    position: relative;
    .menu-list {
      display: flex;
      flex-grow: 1;
    }

    &__decor {
      display: none;
    }
  }

  .menu {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &-bg {
      position: absolute;
      display: none;
      inset: 0;
      width: 100%;
      height: auto;
      opacity: 0.3;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 640px) {
      }
    }
    /* min-width: 1200px */
    @mixin mobile-menu-end-point {
      width: auto;
      flex: 1;
      /* padding-left: ac(60px, 20px); */
      align-items: center;
      .simplebar-content {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .menu-list {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: ac(32px, 20px);
        }

        &:after {
          display: none;
        }
      }
    }

    &:not([data-simplebar='init']) {
      display: none;
    }
  }

  .navbar-nav.active .menu {
    &-bg {
      @media (max-width: 1200px) {
        display: flex;
      }
    }
  }

  .menu-item {
    position: relative;

    @mixin mobile-menu-end-point {
      &:after {
        display: none;
      }
    }

    &__icon {
      display: none;
    }
    &.dropdown {
      & > .menu-link {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;

        &::after {
          content: '\e999';
          font-family: 'icomoon';
          font-size: ac(10px, 8px);
          padding-left: 10px;
        }
      }
    }
  }

  /* mobile menu addt content */
  .menu-additional-content {
    margin-left: ac(48px, 24px);
    .btn {
      height: 48px;
    }
    /* display: none; */
    @mixin mobile-menu-start-point {
      /*transform: translateY(-8px);*/
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: auto;
      margin-left: 0;
      .btn {
        width: 100%;
      }
    }
  }

  /* nav-list */
  .menu-link {
    position: relative;
    color: var(--cl-white);
    transition: all 0.3s ease;
    letter-spacing: 0.24px;
    cursor: pointer;
    font-weight: 800;
    font-size: 16px;
    line-height: 1.4;
    color: #fff;
    i {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -6.5px;
      right: 0;
      width: 0;
      height: 1px;
      background: var(--cl-white);
      transition: all 0.3s ease;
    }
    &.active {
      &::before {
        width: 100%;
        left: 0;
      }
    }

    &:hover {
      color: var(--cl-white-80);
      &::before {
        width: 100%;
        left: 0;
        background: var(--cl-white-80);
      }
    }
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    padding: 10px 10px 10px 15px;
    border-radius: 10px;
    background: var(--cl-grey);
    box-sizing: content-box;
    display: none;

    li {
      .menu-link {
        padding: 5px 0;
        text-transform: capitalize;
      }
    }
  }

  &-bottom {
    display: flex;
  }

  &-slider {
    max-width: 300px;
    overflow: visible;
    margin-bottom: 24px;
    display: none;
  }
  &-card {
    border-radius: 16px;
    padding: 20px 24px;
    max-width: 300px;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.04);
    background: #050505;
    /* border: 1px solid rgba(174, 214, 204, 0.96); */
    --gradient: linear-gradient(
      46deg,
      rgba(145, 243, 218, 0.96) 0%,
      #f4eae5 100%
    );
    &__text {
      min-height: 116px;
      margin-bottom: 47px;
      font-weight: 800;
    }
    &__title {
      font-size: 24px;
      margin-bottom: 12px;
      background: linear-gradient(
        46deg,
        rgba(174, 214, 204, 0.96) 0%,
        #eeeceb 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &__descr {
      font-weight: 400;
      font-size: 17px;
      line-height: 1.4;
      color: #fff;
    }
    &__info {
      &-item {
        width: 100%;
        display: flex;
        align-items: center;
        --gradient: linear-gradient(
          46deg,
          rgba(145, 243, 218, 0.96) 0%,
          #f4eae5 100%
        );
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        i {
          font-size: 22px;
          margin-right: 10px;
          width: 23px;
          &.icon-mail {
            font-size: ac(26px, 25px);
          }
        }
        span {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  }
}

/* end of Common desktop header */

/* ----------------------- Common desktop header Mobile HEADER ----------------------- */

/* min-1200? */
@mixin mobile-menu-start-point {
  .header-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 12px 0 rgba(176, 216, 206, 0.24),
      0 0 16px 0 rgba(176, 216, 206, 0.08);
    background: #050505;
    padding: 24px;
    border-radius: 16px;
    border: 1px solid rgba(176, 216, 206, 0.24);
    width: 100%;
    max-width: 327px;
    --gradient: linear-gradient(
      46deg,
      rgba(145, 243, 218, 0.96) 0%,
      #f4eae5 100%
    );
    @media (max-width: 640px) {
      max-width: 100%;
    }
  }

  /* mobile menu */
  .header:not(.header-mobile) {
    .burger,
    .menu-toggle {
      display: flex;
      margin-right: -2px;
    }
    .burger-wrap {
      display: flex;
    }

    .navbar-nav {
      position: fixed;
      right: 0;
      top: var(--header-height);
      width: 45%;
      /* height: 100%; */
      max-height: calc(var(--vh) * 100);
      height: calc(var(--vh) * 100);
      min-width: 320px;
      padding-top: ac(32px, 20px);
      z-index: -1;
      background: transparent;
      padding-right: 5px;
      padding-bottom: 120px;
      transition: opacity 0.25s ease-out, clip-path 0.5s ease,
        background-color 0.4s ease 0.1s, border-radius 0.3s ease;
      clip-path: circle(25px at calc(100% - 45px) 45px);
      opacity: 0;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      pointer-events: none;
      @media (max-width: 992px) {
        width: 45%;
      }
      @media (max-width: 768px) {
        width: 55%;
      }
      /* @media (min-width: 650px) {
        width: 51% !important;
        .menu-list {
          padding-left: ac(60px, 0px, 650, 1024);
        }
      } */
      /* @mixin media 970 {
        width: 67%;
      } */

      &__image {
        position: absolute;
        inset: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      /* mobile menu active */
      &.active {
        pointer-events: auto;
        clip-path: circle(75%);
        opacity: 1;
        background-color: var(--cl-black);
        .header-slider {
          display: block;
        }
      }

      /* animation */
      &.nav-slide-left {
        right: auto;
        left: 0;
        transform: translateX(-100%);

        &.active {
          transform: translateX(0);
        }
      }
      &.nav-slide-right {
        right: 0;
        left: auto;
        transform: translateX(100%);

        &.active {
          transform: translateX(0);
        }
      }
      &.nav-slide-down {
        right: auto;
        left: 0;
        transform: translateY(-100%);

        &.active {
          transform: translateY(0);
        }
      }
      &.nav-slide-up {
        right: auto;
        left: 0;
        transform: translateY(100%);

        &.active {
          transform: translateY(0);
        }
      }

      /* list, additional content */
      .menu {
        width: 100%;
        max-height: 100%;
        min-height: 100%;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 40px;
        padding-right: 15px;
        align-items: flex-start;
        align-self: center;
        position: relative;
        z-index: 2;
        overflow-x: hidden;
        overflow-y: auto;
        .simplebar-wrapper {
          flex: 1;
        }
        .menu-additional-content {
          /* padding-bottom: 50px; */
          margin-top: 28px;
        }
        .simplebar-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          height: 100%;

          .menu-list {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 0px;
            position: relative;

            &:after {
              position: absolute;
              display: none;
              left: 50%;
              transform: translateX(-50%);
              top: 0;
              width: 173px;
              height: 1px;
              background-color: var(--cl-white);
              content: '';
            }
          }
        }

        .simplebar-track.simplebar-vertical {
          width: ac(6px, 4px);
          background: var(--cl-grey);
          border-radius: 4px;
          /*transform: translateX(ac(6px, 4px));*/
          .simplebar-scrollbar {
            background: var(--cl-navy);
            border-radius: 4px;
            &:before {
              content: none;
            }
          }
        }
      }

      /* mobile list links style */
      .menu-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 0;
        width: auto;

        font-weight: 800;
        font-size: ac(17px, 15px, 640);
        line-height: 1.4;
        background: linear-gradient(
          46deg,
          rgba(174, 214, 204, 0.96) 0%,
          #eeeceb 100%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        i {
          display: block;
          color: #fff;
        }
        &:before {
          height: 1px;
          display: none;
        }
        &:hover {
          opacity: 0.8;
        }
        .active {
        }
      }

      /* mobile list items style */
      .menu-item {
        width: 100%;
        padding: 9px 16px;
        margin-bottom: 12px;
        border: 1px solid rgba(174, 214, 204, 0.96);
        border-radius: 100px;
        &:last-of-type {
          margin-bottom: 0;
          /* @media screen and (max-height: 700px) {
            margin-bottom: 32px;
          } */
        }
      }

      /* padding-bottom: 100px; */
      /* full mobile menu 640 */
      @mixin max-sm {
        width: 100%;
        height: calc(var(--vh) * 100);
        padding-bottom: 100px;
        border-bottom-left-radius: 0;
        .menu {
          padding-left: 20px;
        }
        .header-bottom {
          max-width: 100%;
        }
        .menu-list {
          /* padding-top: 32px; */
          &::after {
            display: none;
          }
        }

        .menu-item {
          justify-content: center;
        }

        .menu-additional-content {
          margin-top: auto;
          justify-content: flex-end;
          /* padding-bottom: 50px; */
          &__buttons {
          }
        }
      }
    }

    .dropdown-menu {
      position: relative;
      top: 0;
    }
  }
}

/* ----------------------- HEADER COMPONENTS ----------------------- */

/* burger */
.burger-wrap {
  display: flex;
  align-items: center;
}
.burger-text {
  font-size: 18px;
  font-weight: 800;
  color: #fff;
  letter-spacing: 0.24px;
  margin-right: 16px;
  font-family: var(--font-fourth);
}
.burger,
.menu-toggle {
  position: relative;
  z-index: 100;
  overflow: hidden;
  transition: 0.3s;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background: linear-gradient(
    46deg,
    rgba(174, 214, 204, 0.96) 0%,
    #eeeceb 100%
  );
  /* base burger */
  .burger-icon {
    cursor: pointer;
    transition: transform 400ms, background-color 0.3s ease;
    user-select: none;
    /* width: 56px !important;
    height: 56px !important; */
    position: relative;
    z-index: 2;
  }

  .line {
    fill: none;
    transition: stroke 0.3s ease, stroke-dasharray 400ms,
      stroke-dashoffset 400ms;
    stroke: var(--cl-black);
    stroke-width: 3;
    stroke-linecap: round;
  }

  .top {
    stroke-dasharray: 40 121;
  }

  .bottom {
    stroke-dasharray: 40 121;
  }

  /* active burger */
  &.active {
    background-color: transparent;
    &:before {
      opacity: 1;
    }
    .line {
      /* stroke: var(--cl-blue-light); */
    }
    .burger-icon {
      transform: rotate(45deg);
    }

    .top {
      stroke-dashoffset: -88px;
    }

    .bottom {
      stroke-dashoffset: -88px;
    }
  }

  /* base burger + hover */
  &:hover {
    .line {
      stroke: rgba(0, 0, 0, 0.8);
    }
  }

  /* active burger + hover */
  &.active {
    &:hover {
      .line {
        stroke: rgba(0, 0, 0, 0.8);
      }
    }
  }
}

/* open menu bg */
.header-close-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 77;
  background: rgba(0, 0, 0, 0.35);
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;

  &.active {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    cursor: pointer;
  }
}
/* end of HEADER COMPONENTS */
