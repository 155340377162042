.testimonials {
    position: relative;
    z-index: 3;
    background: #121212;
    overflow: hidden;
    &__wrapper {
        position: relative;
        padding-top: ac(120px, 40px);
        padding-bottom: ac(80px, 39px);
        z-index: 4;
    }

    &-button {
        padding-top: ac(64px, 32px);
        max-width: max-content;
        margin: 0 auto;
        .btn {
            border: 1px solid var(--cl-black);
        }
    }
    &__title {
        display: flex;
        justify-content: center;
    }
    &-slider {
        margin-top: ac(80px, 104px);

        @media (min-width: 1200px) {
            position: relative;
        }

        .swiper-wrapper {
            position: static;
        }

        .swiper {
            max-width: ac(808px, 650px);
            overflow: visible;
            position: static;
            /* margin: 0; */
            @media (max-width: 1200px) {
                /* width: 100%; */
            }

            @media (min-width: 992px) {
                position: relative;
            }
        }

        .swiper-buttons {
            position: absolute;
            top: ac(135px, 110px, 460, 992);
            left: 50%;
            transform: translateX(-50%);
            @media (max-width: 342px) {
                top: 140px;
            }
            @media (min-width: 992px) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: calc(100% + ac(48px, 8px));
                justify-content: space-between;

                .swiper-button {
                    &.swiper-button-prev {
                        transform: translateX(-100%);
                    }

                    &.swiper-button-next {
                        transform: translateX(100%);
                    }
                }
            }
        }

        .swiper-slide-prev,
        .swiper-slide-next {
            background: #050505;
            border: 1px solid var(--cl-aqua);

            .content-element {
                color: var(--cl-aqua);
            }
        }
    }

    &-list {
        display: flex;
        align-items: center;
    }

    &-card {
        transition: all .3s ease-in;
        padding: ac(40px, 20px) ac(40px, 24px);
        border-radius: ac(20px, 16px);
        max-width: ac(808px, 650px);
        min-height: 455px;
        height: auto;
        background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
        @media (max-width: 900) {
            min-height: 335px;
        }
        &__content {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            justify-content: flex-start;

            color: var(--cl-black);
            font-size: ac(24px, 17px);
            line-height: 1.48;
            transition: all .3s ease-in;

            &-name {
                font-weight: 700;
                font-size: ac(28px, 22px);
                margin-top: auto;
            }

            &-position {
                font-size: ac(16px, 17px);
                padding-top: ac(8px, 6px);
            }

            @media (max-width: 768px) {
                p {
                    line-height: 1.4;
                }
            }
        }
    }

    &__bg {
        position: absolute;
        inset: 0;
        z-index: 1;
        mix-blend-mode: soft-light;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.clients-page {
        &.testimonials {
            background: var(--gradient);
        }

        .testimonials__wrapper {
            padding-bottom: 54px;
        }

        .testimonials-card {
            @media (max-width: 1200px) {
                min-height: 540px;
                height: auto;
            }
        }

        .testimonials__title {
            color: var(--cl-black);
            text-align: center;
        }

        .testimonials-slider {
            margin-top: 64px;

            @media (max-width: 1200px) {
                margin-top: 104px;
            }

            .swiper-slide-prev,
            .swiper-slide-next {
                background: transparent;
                border: 1px solid var(--cl-black);

                .content-element {
                    color: var(--cl-black);
                }
            }

            .swiper-slide-active {
                background: var(--cl-black);

                .content-element {
                    color: var(--cl-white);
                }

                .testimonials-card__content-position {
                    color: var(--cl-aqua);
                }
            }

            .swiper-button {
                background: var(--gradient);
                border-color: var(--cl-black);

                &:before {
                    font-size: 17px;
                    background: var(--cl-black);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    color: transparent;
                }

                &:hover {
                    background: var(--cl-black);
                    border-color: var(--cl-black);

                    &:before {
                        background: var(--gradient);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        color: transparent;
                    }
                }
            }
        }

        .testimonials-button {
            .btn__gradient {
                border-color: var(--cl-black);

                &:hover {
                    background: var(--cl-black);

                    &:after {
                        background: var(--cl-black);
                        color: var(--cl-black);
                    }
                }
            }
        }
    }
}