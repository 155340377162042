.started {
    position: relative;
    z-index: 5;

    &__wrapper {
        padding-top: ac(120px, 64px);
        padding-bottom: ac(120px, 64px);
    }

    &__title {
        &.heading-2 {
            font-size: ac(80px, 46px);
        }

        h2 {
            span {
                font-weight: 700;
                font-size: ac(98px, 54px);
            }
        }
    }

    &__block {
        position: relative;
        background: #050505;
        padding: 32px ac(88px, 24px) ac(50px, 24px);
        z-index: 6;
        box-shadow: 0px 0px 64px 0px #B0D8CE3D, 0px 0px 120px 0px #B0D8CE14;
        border-radius: 20px;
        overflow: hidden;
        max-width: 951px;
        margin: 0 auto;
        text-align: center;
        &:before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            z-index: -1;
            border-radius: inherit;
            padding: 1px;
            background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
            -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
        }

        @media (max-width: 768px) {
            width: 100%;
            padding: 32px 24px 24px;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;

        padding-top: 48px;

        .started__button {
            transition: all .3s ease;
        }

        @media (max-width: 768px) {
            flex-direction: column;
            .started__button {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }

    &__bg {
        position: absolute;
        inset: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}