.our-team {
  position: relative;
  z-index: 10;

  &__wrapper {
    padding-top: ac(80px, 60px);
    padding-bottom: ac(80px, 120px);
    position: relative;

    z-index: 8;
  }

  &__title {
    &.heading-2 {
      font-size: ac(56px, 40px);
    }

    span {
      font-size: ac(60px, 44px);
    }
  }

  &__slider {
    .swiper {
      overflow: visible;
    }
  }

  &__buttons {
    position: absolute;
    top: 90px;
    right: 0;
    @media (max-width: 560px) {
      bottom: 40px;
      left: 0;
    }

    @media (max-width: 1200px) {
      top: 72px;
    }

    @media (max-width: 880px) {
      top: 60px;
    }

    @media (max-width: 560px) {
      top: auto;
      right: auto;
      left: 0;
      bottom: 40px;
    }
  }

  &__bg {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
}
