.expertise-section {
    overflow: hidden;
    z-index: 10;

    &__wrapper {
        z-index: 15;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 992px) {
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 32px;
            padding: ac(64px, 48px, 992, 375) 0;
        }
    }


    &__content {
        font-size: ac(24px, 18px);
        color: var(--cl-aqua);
        min-width: ac(624px, 390px, 992);
        margin-top: 20px;
        @media (max-width: 992px) {
            margin-top: 0;
            max-width: 820px;
        }
    }

    &__title {
        color: var(--cl-white);
        margin-bottom: ac(32px, 20px);
        span {
            font-size: ac(60px, 46px);
        }
    }

    &__subtitle {
        font-weight: 500;
        font-size: ac(26px, 22px);
        color: var(--cl-white);
        margin-bottom: ac(20px, 18px);
    }

    &__descr {
        p {
            &:not(:last-child) {
                margin-bottom: ac(12px, 10px);
            }
        }
    }

    &__button {
        max-width: 204px;
        margin-top: ac(36px, 28px);
        @media (max-width: 1200px) {
            width: 100%;
        }
    }

    &__image {
        /* aspect-ratio: 1 / 1; */
        min-width: ac(652px, 550px, 992);
        transform: translateX(ac(53px, 15px, 992));
        @media (max-width: 992px) {
            transform: translateX(0);
            max-width: 820px;
            min-width: auto;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        /* @media (max-width: 1200px) {
            width: 100%;
            height: 100%;
        } */
    }
}