.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ac(16px, 12px);
  @media (max-width: 551px) {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
}
.btn {
  width: fit-content;
  height: ac(56px, 48px);
  padding: 0px 36px;
  font-size: ac(18px, 17px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  z-index: 1;
  position: relative;
  transition: .3s all;
  .btn__text {
    font-weight: 800;
    line-height: 1.4;
    letter-spacing: 0.45px;
    transition: .3s all;
    min-width: max-content;
  }
  .btn__icon {

  }

  &.btn-border {
    &:after {
      position: absolute;
      inset: 0;
      content: '';
      z-index: -1;
      background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%);;
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      padding: 1px;
      border-radius: inherit;
    }
    .btn__text {
      background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:hover {
      .btn__text {
        background: linear-gradient(46deg, rgba(124, 230, 203, 0.96) 0%, #cbb7ac 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  &.btn-bg {
    background: linear-gradient(46deg, rgba(174, 214, 204, 0.96) 0%, #eeeceb 100%);
    .btn__text {
      color: var(--cl-black);
    }
    &:hover {
      background: linear-gradient(46deg, rgba(126, 224, 202, 0.96) 0%, #c8ada0 100%);
    }
  }

  &__small {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 36px;
    background: var(--gradient);
    border-color: var(--cl-black);
    border-radius: 90px;
    padding: 12px 17px;
    transition: 0.3s;

    i {
      color: var(--cl-black);
      font-size: 13px;
      transition: 0.3s;
    }

    &:hover {
      background: var(--cl-black);

      i {
        color: var(--gradient);
      }

      &:after {
        background: inherit;
      }
    }
  }
}


/* .btn {
  cursor: pointer;
  background: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 100%;
  height: ac(56px, 50px);
  overflow: hidden;
  border-radius: 1400px;
  border: 1px solid;
  align-items: flex-start;
  padding: 15px 36px;
  z-index: 1;
  transition: .3s all;
  &:after {
    position: absolute;
    inset: 0;
    content: '';
    z-index: -1;
    background: var(--gradient);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    border-radius: inherit;
    transition: .3s all;
  }

  .btn__text {
    font-weight: 800;
    font-size: ac(18px, 14px);
    line-height: 1.4;
    background: linear-gradient(
      57.88deg,
      rgba(174, 214, 204, 0.96) 17.35%,
      #eeeceb 76.35%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    transition: .3s all;
  }

  .btn__icon {
    display: flex;
    transition: 0.3s;
  }

  &:hover {
    background: linear-gradient(
      57.88deg,
      rgba(174, 214, 204, 0.96) 17.35%,
      #eeeceb 76.35%
    );

    .btn__text {
      background: var(--cl-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
    }
  }

  &__gradient {
    transition: all 0.3s ease;
    background: linear-gradient(
      57.88deg,
      rgba(174, 214, 204, 0.96) 17.35%,
      #eeeceb 76.35%
    );

    .btn__text {
      background: var(--cl-black);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;
      transition: 0.3s;
    }

    &:hover {
      background: var(--cl-black);

      .btn__text {
        background: linear-gradient(
          57.88deg,
          rgba(174, 214, 204, 0.96) 17.35%,
          #eeeceb 76.35%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
      }
    }
  }

  &__small {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 36px;
    background: var(--gradient);
    border-color: var(--cl-black);
    border-radius: 90px;
    padding: 12px 17px;
    transition: 0.3s;

    i {
      color: var(--cl-black);
      font-size: 13px;
      transition: 0.3s;
    }

    &:hover {
      background: var(--cl-black);

      i {
        color: var(--gradient);
      }

      &:after {
        background: inherit;
      }
    }
  }

  &.btn-bg {
    background: linear-gradient(
      46deg,
      rgba(174, 214, 204, 0.96) 0%,
      #eeeceb 100%
    );
    transition: .3s all;
    .btn__text {
      color: var(--cl-black);
      background: transparent;
      -webkit-text-fill-color: initial;
      transition: .3s all;
    }
  }
} */

.swiper-buttons {
  width: fit-content;
  display: flex;
  gap: 16px;
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  margin: 0 auto;

  .swiper-button {
    position: relative;
    transform: none;
    margin: 0;
    left: auto;
    right: auto;
    top: auto;
    bottom: auto;
    display: flex;
    width: 80px;
    height: 56px;
    justify-content: center;
    align-items: center;
    color: var(--cl-blue, #346c50);
    border: 1px solid var(--cl-white);
    border-radius: ac(100px, 86px);
    transition: 0.3s;
    background-color: var(--cl-black);

    @media (max-width: 560px) {
      width: 68px;
      height: 48px;
    }

    &::after {
      display: none;
    }

    &::before {
      content: '\e905';
      display: block;
      font-family: 'icomoon' !important;
      font-size: 24px;

      background: linear-gradient(
        57.88deg,
        rgba(174, 214, 204, 0.96) 17.35%,
        #eeeceb 76.35%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      color: transparent;

      @media (max-width: 560px) {
        font-size: 14px;
      }
    }

    &.swiper-button-prev {
      &::before {
        content: '\e902';
      }
    }

    &:hover {
      background: var(--gradient);
      color: var(--cl-white, #fff);

      &:before {
        background: var(--cl-black);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
      }
    }

    &.swiper-button-lock {
      display: none;
    }
  }
}
