.faq-section {
  position: relative;
  z-index: 5;
  background: linear-gradient(
    57.88deg,
    rgba(174, 214, 204, 0.96) 17.35%,
    #eeeceb 76.35%
  );
  border: 0px 0px 1px 0px;

  overflow: hidden;

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: ac(120px, 48px);
    padding-bottom: ac(108px, 48px);
    z-index: 6;

    @media (min-width: 1200px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__title {
    color: var(--cl-black);
    @media (max-width: 1200px) {
      width: 100%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: ac(8px, 16px);

    margin-top: 24px;

    @media (min-width: 1200px) {
      margin: 0;
      width: 71.24%;
    }
  }

  &__item {
    color: var(--cl-black);
    width: 100%;
    cursor: pointer;

    &-title {
      font-weight: 500;
      font-size: ac(28px, 22px);
      line-height: 132%;
      letter-spacing: 1.3px;

      @media (min-width: 1200px) {
        width: 61.47%;
      }
    }

    &-button {
      font-size: 28px;
      cursor: pointer;
      transition: .3s;
      i {
        transition: 0.3s;
      }
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #050505;
      padding: 16px 0;

      &:hover {
        color: var(--gradient);
      }

      @media (min-width: 1200px) {
        padding: 24px 0;
      }
    }

    &-bottom {
      margin-top: ac(16px, 24px);
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transition: 0.3s;
      width: 100%;
      &-content {
        transition: 0.3s;
        ol {
          list-style-type: revert-layer;
          padding-left: ac(35px, 30px, 640);
        }
        ul {
          padding-left: ac(35px, 15px, 640);
          list-style-type: disc;
        }
        p {
          &:not(:last-child) {
            margin-bottom: ac(12px, 10px);
          }
        }
        p, li {
          font-size: ac(22px, 17px);
          line-height: 148%;
        }
        a {
          text-decoration: underline;
          text-decoration-color: transparent;
          transition: .3s all;
          &:hover {
            text-decoration-color: var(--cl-black);
          }
        }
      }

      &-text {
        width: 100%;
      }
    }

    &.active {
      .faq-section__item-bottom {
        max-height: 1000px;
        opacity: 1;
        transition: 0.3s;
      }
    }

    &:not(.active) {
      .faq-section__item-bottom {
        max-height: 0 !important;
      }
    }
  }

  &__bg {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    mix-blend-mode: soft-light;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &.clients-page {
    &.faq-section {
      background: linear-gradient(
              125deg,
              rgba(174, 214, 204, 0.96) 17.35%,
              #eeeceb 76.35%
      );
    }

    .faq-section__wrapper {
      padding-top: 54px;
    }
  }
}
