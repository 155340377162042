.meta-input,
.meta-select,
.meta-upload-file {
  label,
  .label {
    font-size: ac(16px, 14px);
    line-height: 1.32;
    font-weight: 600;
    padding-bottom: ac(13px, 8px);
    cursor: pointer;
    display: flex;
    letter-spacing: 1.28px;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    color: var(--cl-navy);
    span {
      font-size: ac(20px, 18px);
      display: inline-block;
      color: #dc2626;
      font-weight: bold;
      margin-left: ac(2px, 1px);
    }
  }
}

.dark-bg {
  .meta-input,
  .meta-select,
  .meta-upload-file {
    label,
    .label {
      color: var(--cl-white);
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 100px;
  height: ac(56px, 50px);
  input,
  textarea {
    width: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--cl-aqua);
    /* border: 1px solid #B9DFEF; */
    border-radius: 100px;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 10px ac(16px, 14px);
    padding-left: ac(26px, 17px);
    height: ac(56px, 50px);
    background: transparent;
    @mixin transparent-bg-input var(--cl-aqua);

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-aqua-80);
      opacity: 1;
    }

    &:focus {
      border-color: var(--cl-navy);
      caret-color: var(--cl-aqua);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }

    /*&.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(126, 207, 174, 0.3);
    }*/
  }

  textarea {
    /* padding-top: 16px; */
    /* min-height: ac(78px, 90px); */
    /* max-height: ac(78px, 90px); */
    overflow-y: auto;
    resize: none;
    /* clip-path: inset(-1px -1px -1px -1px round ac(39px, 28px)); */
    /* border-radius: ac(39px, 28px); */

    scrollbar-width: thin;
    scrollbar-color: var(--cl-primary) transparent;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-primary);
      border-radius: 2px;
    }
  }

  &.dark-border {
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 700;
  }

  &__help {
    color: var(--cl-white);
  }

  /*&__error {
    color: var(--cl-red);
  }*/

  &.form-type {
    height: auto;
    border-radius: 0;

    label,
    .label {
      font-size: 16px;
      line-height: 1.48;
      font-weight: 400;
      padding-bottom: 4px;
      cursor: pointer;
      display: flex;
      letter-spacing: 0;
      align-items: center;
      justify-content: flex-start;
      text-transform: none;
      color: var(--cl-black);
    }

    input,
    textarea {
      border-radius: 8px;
      background-color: transparent;
      border: 1px solid rgba(18, 18, 18, 0.12);
      padding-left: 19px;
      padding-right: 19px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--cl-black);
      @mixin transparent-bg-input var(--cl-black);
      &:focus {
        caret-color: var(--cl-black);
      }
      &::placeholder {
        color: var(--cl-black);
        opacity: 1;
      }
    }

    textarea {
      padding-top: 16px;
      min-height: ac(96px, 90px);
      max-height: ac(96px, 90px);
      overflow-y: auto;
      resize: none;
      clip-path: inset(-1px -1px -1px -1px round 8px);
      border-radius: 8px;

      scrollbar-width: thin;
      scrollbar-color: var(--cl-aqua) transparent;
    }
  }

  &.profile-type {
    input,
    textarea {
      border-radius: 8px;
      background-color: var(--cl-white);
      border: 1px solid rgba(53, 53, 63, 0.3);
      padding-left: 19px;
      padding-right: 19px;
      font-size: ac(18px, 16px);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--cl-charcoal);
      @mixin transparent-bg-input var(--cl-charcoal);

      &::placeholder {
        color: var(--cl-charcoal);
        opacity: 0.8;
      }

      &:focus {
        border-color: var(--cl-navy-80);
      }
    }

    textarea {
      clip-path: inset(-1px -1px -1px -1px round 8px);
      border-radius: 8px;
      min-height: ac(184px, 90px);
      max-height: ac(184px, 90px);
    }
  }
}

.dark-bg {
  .meta-input {
    input,
    textarea {
      &:focus {
        border-color: var(--cl-aqua);
      }
    }
  }
}

.is-safari {
  .meta-input {
    textarea {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 100px;
  height: ac(56px, 50px);
  select {
    opacity: 0;
    height: ac(56px, 50px);
  }
  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;
    &:not(.is-open) {
      overflow: hidden;
    }
    &__inner {
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 13px 29px !important;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      height: ac(56px, 50px);
      background: transparent;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      color: var(--cl-aqua);
      border: none;
      border-radius: 100px !important;
      outline: none;
      /*&:hover {
        border-color: var(--cl-orange);
      }*/
      /*&.error {
        border-color: var(--cl-red);
        box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
      }*/
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-top: 8px;
        }
      }
      &.is-flipped {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 8px;
        }
      }
    }
    &__item {
      @mixin max-line-length-one;
    }
    &__placeholder {
      color: var(--cl-aqua-80);
      opacity: 1;
      background: transparent;
    }
    &__list {
      padding: 0;
      background-color: #121212;
      .choices__item {
        padding-right: ac(36px, 30px);
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        color: var(--cl-aqua);
        transition: all 0.3s ease;
        text-decoration: underline;
        text-decoration-color: transparent;
        &.is-selected {
          color: var(--cl-aqua);
        }
      }
      &--single {
        background-color: transparent !important;
        .choices__item {
          color: var(--cl-aqua);
        }
      }
      &--multiple {
        color: var(--cl-aqua-80);
        .choices__item--selectable {
          display: none;
        }
        + .choices__input {
          display: none;
        }
      }
      &--dropdown {
        .choices__item {
          color: var(--cl-aqua-80);
          text-decoration: none !important;
          &:hover {
            /* &:not(.is-selected) {
              color: var(--cl-grey) !important;
              text-decoration: none !important;
            } */
          }
        }
      }
    }
    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        border: none;
        content: '\e900';
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;
        position: absolute;
        right: 16.5px;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.15s ease-in-out;
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        width: ac(20px, 11px);
        height: ac(20px, 18px);
        margin-top: 0;
        font-size: ac(11px, 8px);
        z-index: 2;
        color: #fff;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      &:before {
        position: absolute;
        width: ac(48px, 38px);
        height: ac(48px, 38px);
        border: 1px solid var(--cl-aqua);
        border-radius: 50%;
        background: transparent;
        content: '';
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        right: 4px;
        transition: 0.3s all;
      }
    }
    &.is-open {
      &::after {
        /* transform: scaleY(-1) translateY(-50%); */
        /* top: 36%; */
        transform: rotate(180deg) translateY(50%);
        /* top: 11%; */
      }
    }
    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;
      /* &:not(.is-selected) {
        color: var(--cl-black);
      } */
    }
    &__list--dropdown,
    .choices__list[aria-expanded] {
      border: 1px solid var(--cl-aqua);
      border-radius: 0;
    }
    &[data-type*='select-one'] .choices__input {
      /* margin: 2px;
      max-width: calc(100% - 4px);
      border: 1px solid var(--cl-black);
      border-radius: 0;
      background: var(--cl-white);
      color: var(--cl-black);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main); */
    }
    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }
}
.dark-bg {
  .meta-select {
    label {
      color: var(--cl-white);
    }
    .choices {
      &__inner {
        border-color: var(--cl-white);
      }
      &[data-type*='select-one'],
      &[data-type*='select-multiple'] {
        cursor: pointer;
        &:after {
          color: var(--cl-white);
        }
        &:before {
          border: 1px solid var(--cl-white);
          border-radius: 50%;
          background: transparent;
        }
      }
      &__list--single {
        .choices {
          &__item--selectable {
            color: var(--cl-white) !important;
          }
        }
      }
    }
  }
}

.password-input {
  position: relative;

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    right: ac(20px, 14px);
    bottom: ac(16px, 12px);
    position: absolute;
    z-index: 10;
    --size: ac(24px, 22px);
    width: var(--size);
    height: var(--size);
    cursor: pointer;
    color: var(--cl-charcoal);
    transition: 0.3s;

    i {
      font-size: 16px;

      &.icon-not-show {
        font-size: 18px;
      }
    }

    &:hover {
      color: var(--cl-navy);
    }
  }
}

/* .meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  label,
  .label {
    color: #757575;
    padding-bottom: ac(16px, 12px);
    cursor: pointer;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(17px, 10px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(13px, 10px);
    }
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: normal;
    border: 1px solid var(--cl-navy);
    padding: ac(14px, 10px) ac(28px, 24px);
    border-radius: 12px;
    transition: all 0.3s ease;
    background: var(--cl-white);
    cursor: pointer;
    position: relative;
    height: ac(56px, 48px);

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
      color: var(--cl-navy);
      transition: 0.3s;
    }

    &:hover {
      background-color: var(--cl-aqua);
      border-color: var(--cl-charcoal);
      color: var(--cl-charcoal);

      span {
        color: var(--cl-charcoal);
      }
    }
  }

  &__info {
    font-size: ac(14px, 12px);
    line-height: normal;
    font-style: italic;
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-primary);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-primary) / 0.3);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }
} */

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  label,
  .label {
    font-size: 16px;
    line-height: 1.48;
    font-weight: 400;
    padding-bottom: 4px;
    cursor: pointer;
    display: flex;
    letter-spacing: 0;
    align-items: center;
    justify-content: flex-start;
    text-transform: none;
    color: var(--cl-black);
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    grid-column-gap: ac(17px, 10px);
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(13px, 10px);
    }
  }

  &__input {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(17px, 16px);
    line-height: normal;
    font-weight: 600;
    font-family: var(--font-main);
    color: var(--cl-white);
    border: 2px solid var(--cl-black);
    border-radius: 28px;
    background: var(--cl-black);
    transition: all 0.3s ease;
    padding: 4px ac(28px, 18px);
    cursor: pointer;
    position: relative;
    height: ac(44px, 40px);
    text-transform: uppercase;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
      color: var(--cl-white);
    }

    &:hover {
      background-color: transparent;
      color: var(--cl-black);
    }
  }

  &__file-name {
    max-width: calc(100% - ac(122px, 114px));
    @mixin max-line-length-one;
    font-family: var(--font-second);
    font-size: ac(17px, 15px);
    font-weight: 300;
    color: var(--cl-black);
  }

  &__info {
    margin-top: 6px;
    font-size: ac(13px, 12px);
    line-height: normal;
    font-weight: 300;
    font-style: italic;
    color: var(--cl-black);
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-aqua);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-black) / 0.3);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }

  &.second-type {
    .meta-upload-file {
      &__input {
        border-color: var(--cl-purple);
        background: var(--cl-purple);

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    --size: ac(32px, 24px);
    border-radius: ac(5px, 3px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--size);
    min-width: var(--size);
    height: var(--size);
    border: 1px solid rgba(5, 5, 5, 0.5);
    background-color: transparent;
    margin-right: ac(12px, 10px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    &:hover {
      border-color: var(--cl-purple);
    }
    &::before {
      content: '\e90d';
      font-family: 'icomoon' !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: #fff;
      opacity: 0;
      font-size: 14px;
      display: none;
    }
    .check-clip,
    .circle-clip {
      width: 75%;
      height: 75%;
      background-color: var(--cl-black);
      opacity: 0;
      transition: opacity 0.3s ease, background-color 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      /* opacity: 0;
      font-size: ac(10px, 8px);
      color: var(--cl-charcoal);
      transition: opacity 0.3s ease, color 0.3s ease; */
    }
  }

  input {
    display: none;
  }

  &__text {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.4;
    color: rgba(5, 5, 5, 0.8);
    &:hover {
      color: rgba(56, 65, 84, 0.805);
    }
    a {
      font: inherit;
      color: var(--cl-black);
      text-decoration: underline;
      text-decoration-color: var(--cl-black);
      transition: all 0.25s ease;
      font-weight: 600;
      &:hover {
        color: var(--cl-purple);
        text-decoration-color: var(--cl-purple);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    border-color: var(--cl-white);
    background-color: var(--cl-white);
    &::before {
      opacity: 1;
    }
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
      color: var(--cl-black);
    }

    &:hover {
      border-color: var(--cl-purple);
    }
  }
}
