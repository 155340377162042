.ethos {
  padding-top: ac(417px, 280px);
  padding-bottom: ac(80px, 64px);
  overflow: hidden;
  &-nav {
    margin-bottom: ac(80px, 42px);
  }
  &-title {
    font-weight: 600;
    font-size: ac(56px, 34px);
    line-height: 1.12;
    letter-spacing: 0.01em;
    color: var(--cl-black);
    margin-bottom: ac(80px, 42px);
    text-align: center;
  }
  &-wrap {

  }
  &-slider {
    overflow: visible;
    max-width: 392px;
    margin-left: 0;
    @media (max-width: 640px) {
      max-width: ac(392px, 280px, 320, 640);
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: ac(48px, 24px);
      width: 100%;
      gap: ac(32px, 24px);
      @media (max-width: 820px) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        gap: ac(24px, 16px, 375, 820);
      }
    }
  }

  .swiper-scrollbar {
    position: relative;
    width: 100%;
    inset: auto;
    background-color: rgba(18, 18, 18, 0.2);
    &-drag {
      background-color: var(--cl-black-2);
    }
  }

  &-card {
    height: ac(525px, 480px);
    position: relative;
    overflow: hidden;
    border: 1px solid #050505;
    border-radius: 20px;
    padding: ac(38px, 20px);
    max-width: 392px;
    @media (max-width: 640px) {
      max-width: ac(392px, 280px, 320, 640);
    }
    &__arrow {
      width: 114px;
      height: 114px;
      border-radius: 100%;
      background-color: var(--cl-black-2);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -57px;
      cursor: pointer;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      transition: .3s all;
      &:hover {
        .ethos-card__arrow-chevron {
          transform: translateY(-3px);
        }
      }
      &-chevron {
        position: absolute;
        top: 28px;
        width: 33px;
        height: 17px;
        transition: .3s all;
        &::before, &::after {
          content: '';
          position: absolute;
          width: 1px;
          background-color: #B0D8CE;
          border-radius: 2px;
        }
        &::before {
          height: 20px;
          top: 0px;
          left: 72%;
          transform: translateX(-50%);
          rotate: -45deg;
        }
        &::after {
          height: 20px;
          top: 0.3px;
          left: 32%;
          transform: translateX(-50%) rotate(45deg);
        }
      }

    }
    &__bg {
      /* opacity: 0.2; */
      background-color: rgba(176, 216, 206, 0.2);
      mix-blend-mode: luminosity;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 1;
      opacity: 1;
    }
    &::before {
      content: '';
      background: #b0d8ce;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 1;
      opacity: 0.2;
    }
    &__overlay {
      background: linear-gradient(0deg, rgba(18, 18, 18, 0.4) 0%, rgba(18, 18, 18, 1) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
      z-index: 0;
      opacity: 0.2;
    }
    &__image {
      position: absolute;
      inset: 0;
      z-index: -1;
      opacity: 0.2;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        /* mix-blend-mode: luminosity; */
      }
    }
    &__nav {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      gap: ac(24px, 16px);
      transition: .3s all;
    }
    &__num {
      font-style: italic;
      font-weight: 300;
      font-size: ac(56px, 48px);
      line-height: 1.12;
      color: var(--cl-black);
      font-family: var(--font-second);
      width: 100%;
      border-bottom: 2px solid var(--cl-black);
      padding-bottom: 4px;
      transition: .3s all;
    }
    &__title {
      font-weight: 600;
      font-size: ac(28px, 24px);
      line-height: 1.28;
      letter-spacing: 0.01em;
      color: var(--cl-black);
      transition: .3s all;
    }
    &__content {
      height: auto;
      opacity: 1;
      z-index: 32;
      position: relative;
      transform: translateY(200%);
      margin-top: 24px;
      transition: .3s all;
      max-height: 354px;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: var(--cl-green) transparent;
      @media (max-width: 640px) {
        max-height: 260px;
      }
    }
    &__descr {
      font-weight: 400;
      font-size: 19px;
      line-height: 1.48;
      color: #fff;
      p {
        &:not(:last-child) {
          margin-bottom: ac(16px, 10px);
        }
      }
    }

    &.active {
      &::before {
        opacity: 1;
        mix-blend-mode: color;
      }
      .ethos-card {
        &__arrow {
          opacity: 0;
          bottom: -180px;
        }
        &__content {
          transform: translateY(0);
          opacity: 1;
          /* height: auto; */
        }
        &__image {
          opacity: 1;
        }
        &__overlay {
          opacity: 1;
        }
        &__bg {
          opacity: 0;
        }
        &__nav {
          @media (min-width: 580px) {
            flex-direction: row;
            align-items: center;
          }
        }
        &__title {
          color: #FFF;
          font-size: ac(23px, 20px);
          @media (min-width: 580px) {
            max-width: 215px;
          }
        }
        &__num {
          color: #FFF;
          border-color: #FFF;
          @media (min-width: 580px) {
            display: flex;
            justify-content: center;
            height: 100%;
            max-width: 64px;
          }
        }
      }
    }
  }
}