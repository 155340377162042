.opportunities {
  padding-top: ac(80px, 72px);
  padding-bottom: ac(80px, 72px);
  &-bg {
    position: absolute;
    inset: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      position: absolute;
      inset: 0;
      z-index: 1;
      background: linear-gradient(
        180deg,
        rgba(5, 5, 5, 0) 0%,
        rgba(5, 5, 5, 0) 78%,
        rgba(5, 5, 5, 1) 100%
      );
      content: '';
    }
  }
  &-wrap {
  }

  &-nav {
    margin-bottom: ac(80px, 40px);
    position: relative;
    z-index: 10;

    &__form {
      display: flex;
      justify-content: space-between;
      @media (max-width: 840px) {
        flex-wrap: wrap;
        gap: 14px;
        justify-content: center;
      }
      .meta-input {
        max-width: ac(392px, 200px);
        margin-right: ac(16px, 14px);
        width: 100%;
        --gradient: linear-gradient(
          46deg,
          rgba(145, 243, 218, 0.96) 0%,
          #f4eae5 100%
        );
        @media (max-width: 840px) {
          max-width: 400px;
          margin-right: 0;
        }
        @media (max-width: 520px) {
          max-width: 100%;
        }
      }
      .meta-select {
        --gradient: linear-gradient(
          46deg,
          rgba(145, 243, 218, 0.96) 0%,
          #f4eae5 100%
        );
        width: 100%;
        margin-right: ac(16px, 14px);
        &.job-type {
          max-width: ac(288px, 240px);
        }
        &.job-sector {
          max-width: ac(310px, 260px);
          margin-right: 0;
        }
        @media (max-width: 840px) {
          max-width: 300px;
          margin-right: 0;
        }
        @media (max-width: 520px) {
          max-width: 100% !important;
        }
      }
      .btn {
        margin-left: ac(24px, 14px);
        max-width: ac(178px, 150px);
        width: 100%;
        @media (max-width: 840px) {
          margin-left: 0;
        }
        @media (max-width: 520px) {
          max-width: 100%;
        }
        .btn__icon {
          display: flex;
        }
        .btn__icon i {
          color: var(--cl-black);
          font-size: 20px;
          margin-right: ac(8px, 4px);
        }
      }
    }
  }
  &-title {
    margin-bottom: ac(40px, 24px);
    font-weight: 600;
    font-size: ac(48px, 28px);
    line-height: 1.12;
    color: #fff;
    max-width: ac(520px, 460px);
    span {
      font-family: 'Ysabeau', sans-serif;
      font-style: italic;
      font-weight: 700;
      font-size: ac(56px, 32px);
      background: linear-gradient(
        46deg,
        rgba(145, 243, 218, 0.96) 0%,
        #f4eae5 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &-jobs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ac(24px, 12px, 1200);
    @media (max-width: 930px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 640px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &-job {
    max-width: 392px;
    padding-top: ac(28px, 18px);
    padding-right: ac(32px, 20px, 992);
    padding-bottom: ac(43px, 22px);
    padding-left: ac(36px, 20px, 992);
    border-radius: 16px;
    cursor: pointer;
    /* background: linear-gradient(46deg, rgba(145, 243, 219, 0) 0%, #f4eae500 100%); */
    --gradient: linear-gradient(
      46deg,
      rgba(145, 243, 218, 0.96) 0%,
      #f4eae5 100%
    );
    transition: 0.3s all;
    position: relative;
    overflow: hidden;
    &:before {
      transition: 0.3s all;
      content: '';
      opacity: 0;
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        46deg,
        rgba(145, 243, 218, 0.96) 0%,
        #f4eae5 100%
      );
      z-index: -1;
    }
    &__title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: ac(28px, 24px);
      line-height: 1.32;
      color: var(--cl-white);
      transition: 0.3s all;
    }
    &__company {
      color: var(--cl-blue);
      font-weight: 600;
      font-size: ac(20px, 18px);
      line-height: 1.48;
      margin-bottom: ac(24px, 20px);
      transition: 0.2s all;
    }
    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-bottom: ac(20px, 18px);
    }
    &__tag {
      padding: ac(9px, 6px);
      padding-right: ac(12px, 8px);
      font-weight: 500;
      font-size: ac(16px, 14px);
      line-height: 1.48;
      color: var(--cl-aqua);
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 6px;
      display: flex;
      align-items: center;
      transition: 0.2s all;
      i {
        margin-right: ac(6px, 4px);
        font-size: ac(20px, 18px);
        color: var(--cl-aqua);
      }
    }
    &__link {
      font-weight: 800;
      font-size: ac(18px, 16px);
      line-height: 1.4;
      letter-spacing: 0.03em;
      color: var(--cl-white);
      display: flex;
      align-items: center;
      transition: 0.2s all;
      &:hover {
        opacity: 0.85;
        i {
          transform: translateX(4px);
        }
      }
      &-wrap {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .btn__icon {
        display: flex;
      }
      i {
        transition: 0.2s all;
        margin-left: ac(16px, 14px);
      }
    }

    &:hover {
      /* background: linear-gradient(46deg, rgba(145, 243, 218, 0.96) 0%, #f4eae5 100%); */
      &::after {
        opacity: 0;
      }
      &::before {
        opacity: 1;
      }
      .opportunities-job {
        &__title {
          color: var(--cl-black-2);
        }
        &__company {
          color: rgba(5, 5, 5, 0.7);
        }
        &__tag {
          color: rgba(18, 18, 18, 0.8);
          background: linear-gradient(
            46deg,
            rgba(174, 214, 204, 0.5) 0%,
            rgba(238, 236, 235, 0.5) 100%
          );
          border: 1px solid rgba(5, 5, 5, 0.2);
          i {
            color: rgba(18, 18, 18, 0.6);
          }
        }
        &__link {
          color: var(--cl-black-2);
        }
      }
    }
  }

  &-bottom {
    width: 100%;
    text-align: center;
    margin-top: ac(55px, 32px);
  }
  &-more {
    font-weight: 800;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: 0.03em;
    background: linear-gradient(
      46deg,
      rgba(145, 243, 218, 0.96) 0%,
      #f4eae5 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.23s all;
    &:hover {
      transform: translateY(-3px);
      opacity: 0.85;
    }
  }
}
