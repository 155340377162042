.team {
  &-list {
    padding-top: ac(64px, 32px);
  }

  &-card {
    position: relative;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    cursor: pointer;

    background: var(--cl-white);
    overflow: hidden;
    .btn {
      border: 1px solid var(--cl-black);
    }
    &__top {
      position: relative;
      width: 100%;
      overflow: hidden;
      background: var(--cl-aqua);
      min-height: 335px;

      &-bg {
        position: relative;
        inset: 0;
        transition: 0.3s;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &-image {
        position: absolute;
        inset: 0;
        z-index: 2;

        padding: 19px 0 0;

        img {
          width: 100%;
          height: 100%;

          object-fit: contain;
          object-position: bottom;
          transition: 0.3s;
        }

        @media (max-width: 560px) {
          padding: 18px 0 0;
        }
      }
    }

    &__bottom {
      padding: ac(24px, 20px) ac(32px, 24px);
      font-weight: 600;

      &-name {
        color: var(--cl-black);
        font-size: ac(32px, 28px);
      }

      &-position {
        font-size: ac(17px, 18px);
        color: var(--cl-purple);
      }

      &-social {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-top: ac(32px, 24px);

        a {
          svg {
            transition: 0.3s ease;
          }

          &:hover {
            svg {
              transform: scale(1.1);
            }
          }
        }

        span {
          color: var(--cl-black);
          font-size: ac(20px, 17px);
          line-height: 1.4;
          font-weight: 800;

          padding-left: 32px;
          padding-right: 12px;
          white-space: nowrap;
        }
      }
    }

    &:hover {
      .team-card__top-bg {
        transform: scale(1.1);
      }

      /*.team-card__top-image {
        img {
          transform: scale(1.05);
        }
      }*/
    }
  }
}
