.cooperate {
    position: relative;
    z-index: 5;

    background: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);

    &__wrapper {
        position: relative;
        padding-top: ac(120px, 56px);
        padding-bottom: ac(120px, 56px);
        z-index: 6;
    }

    .js-marquee {
        display: flex;
        gap: ac(20px, 16px);

        &__wrapper {
            display: flex;
        }
    }

    .js-marquee-wrapper {
        display: flex;
    }

    &__title {
        color: var(--cl-black);
        margin-bottom: ac(64px, 40px);
    }

    &__logo {
        display: grid;
        place-items: center;
        padding: 5px;
        background-color: var(--cl-white);
        width: 236px;
        height: 140px;
        border: 1px solid var(--cl-aqua);
        border-radius: 20px;
        overflow: hidden;

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }

    @media (min-width: 1536px) {
        .cont {
            max-width: 100%;
            padding: 0;
        }
    }

    &__marquee {
        display: flex;
        gap: ac(16px, 12px);

        margin-bottom: ac(24px, 20px);
    }

    &__bg {
        position: absolute;
        inset: 0;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}