.get-to-section {
    background: var(--gradient);
    overflow: hidden;
    z-index: 10;

    &__wrapper {
        padding-top: ac(108px, 48px);
        padding-bottom: ac(108px, 48px);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 28px;
        color: var(--cl-black);
        @media (max-width: 860px) {
            flex-direction: column;
        }
    }

    &__title {
        font-weight: 600;
        font-size: ac(56px, 38px);
        line-height: 1.12;
        letter-spacing: 0.01em;
        color: var(--cl-black);
        /* min-width: ac(390px, 265px); */
        text-wrap: nowrap;
    }

    &__content {
        max-width: ac(704px, 640px);
        font-weight: 400;
        font-size: ac(24px, 18px);
        line-height: 1.48;
        color: var(--cl-black);
        @media (max-width: 860px) {
          max-width: none;
        }
        p {
          &:not(:last-child) {
            margin-bottom: ac(18px, 10px);
          }
        }
    }

    &__button {
        margin-top: ac(36px, 20px);
        width: 100%;
        .btn {
            border: 1px solid var(--cl-black);
            /* &__text {
                background: var(--cl-black);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                color: transparent;
            } */
        }
    }

    &__bg {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        mix-blend-mode: soft-light;

        img {
            width: 100%;
            height: 100%;

            object-fit: cover;
        }
    }

    &.for-candidates {
        background: transparent;
    }
}