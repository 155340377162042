a {
  font-size: ac(20px, 16px);
  font-weight: 500;
  display: inline-block;
  line-height: 100%;
}

.heading-gradient {
  span {
    font-family: var(--font-second);
    line-height: 112%;
    font-style: italic;
    background: linear-gradient(76.44deg, rgba(145, 243, 218, 0.96) 41.08%, #F4EAE5 80.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    letter-spacing: ac(0.9px, 0.68px);
  }
}

.heading-1 {
  font-weight: 600;
  font-size: ac(80px, 46px);
  line-height: 112%;
  letter-spacing: ac(1.2px, 0.69px);
  color: var(--cl-white);
}

.heading-2 {
  font-weight: 600;
  font-size: ac(56px, 42px);
  letter-spacing: ac(0.84px, 0.62px);
  line-height: 112%;
}

.heading-3 {
  font-weight: 600;
  font-size: ac(36px, 28px);
  line-height: 112%;
}

.heading-4 {
  font-weight: 600;
  font-size: ac(80px, 60px);
  line-height: 112%;
}

.content-element {
  font-size: ac(22px, 14px);
  font-weight: 400;
  line-height: 1.4;

  ol,
  ul {
    li {
      list-style-position: inside;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  ul, ol,
  p {
    /*Not Design*/
    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }

    /*a {
      font: inherit;
      color: var(--cl-orange);
      transition: color .3s ease;
      text-decoration: underline;

      &:hover {
        color: var(--cl-slate);
      }
    }*/
  }

  &.big-size {
    p, li {
      font-family: var(--font-third);
      font-size: ac(26px, 18px);
      line-height: 1.4;
      font-weight: 600;
      font-style: normal;

      &:not(:last-child) {
        margin-bottom: ac(24px, 16px);
      }
    }
  }
}