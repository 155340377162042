.supporting {
  &-wrap {
    --gradient: linear-gradient(
      46deg,
      rgba(145, 243, 218, 0.96) 0%,
      #f4eae5 100%
    );
    box-shadow: 0 0 64px 0 rgba(176, 216, 206, 0.24),
      0 0 120px 0 rgba(176, 216, 206, 0.08);
    background: var(--cl-black);
    border-radius: ac(36px, 24px);
    overflow: hidden;
    position: relative;
    padding: ac(80px, 24px) ac(80px, 24px);
    display: flex;
    justify-content: flex-end;
    margin-bottom: ac(-309px, -180px);
    /* transform: translateY(ac(-325px, -180px)); */

    @media (max-width: 360px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-image {
    position: absolute;
    inset: 0;
    z-index: -1;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.31);
      position: absolute;
      inset: 0;
      display: none;
      @media (max-width: 900px) {
        display: block;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-text {
    max-width: ac(600px, 540px);
    @media (max-width: 900px) {
      max-width: 100%;
    }
  }
  &-title {
    margin-bottom: ac(24px, 18px);
    font-weight: 600;
    font-size: ac(56px, 32px);
    line-height: 1.12;
    color: #fff;
    span {
      font-family: 'Ysabeau', sans-serif;
      font-style: italic;
      font-weight: 700;
      font-size: ac(60px, 36px);
      background: linear-gradient(
        46deg,
        rgba(145, 243, 218, 0.96) 0%,
        #f4eae5 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding-left: 3px;
    }
  }
  &-descr {
    font-size: ac(20px, 18px);
    line-height: 1.48;
    color: #fff;
    p {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }
  }
}
