@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,opsz,wght@0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700&family=Inter:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rethink+Sans:ital,wght@0,400..800;1,400..800&family=Ysabeau:ital,wght@0,1..1000;1,1..1000&display=swap');

/* @font-face {
  font-family: 'Rethink Sans';
  src: url('../../assets/fonts/RethinkSans-Regular.woff2') format('woff2'),
  url('../../assets/fonts/RethinkSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: 'Ysabeau Office';
  src: url('../../assets/fonts/YsabeauOffice-Bold.woff2') format('woff2'),
  url('../../assets/fonts/YsabeauOffice-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
} */

/* @font-face {
  font-family: 'Ysabeau';
  src: url('../../assets/fonts/Ysabeau-BoldItalic.woff2') format('woff2'),
  url('../../assets/fonts/Ysabeau-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
} */

@font-face {
  font-family: 'Archivo';
  src: url('../../assets/fonts/Archivo-SemiBold.woff2') format('woff2'),
  url('../../assets/fonts/Archivo-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

:root {
  --cl-black: #050505;
  --cl-black-rgb: 0 0 0;
  --cl-black-80: rgba(5, 5, 5, 0.8);
  --cl-black-2: #121212;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --gradient: linear-gradient(57.88deg, rgba(174, 214, 204, 0.96) 17.35%, #EEECEB 76.35%);
  --cl-white: #F0EEEE;
  --cl-white-rgb: 255 255 255;
  --cl-white-80: rgba(240, 238, 238, 0.8);

  --cl-orange: #EC731F;

  --cl-purple: #9378C8;

  --cl-green: #A6D1C7;

  --cl-aqua: #B0D8CE;
  --cl-aqua-80: rgba(176, 216, 206, 0.8);

  --cl-grey: #D2D2D2;
  --cl-blue: #4bafe4;

  --cl-light-grey: #F6F6F6;

  --cl-slate: #20232E;

  --font-main: 'Archivo', sans-serif;
  --font-second: 'Ysabeau', sans-serif;
  --font-third: 'Ysabeau Office', sans-serif;
  --font-fourth: "Rethink Sans", sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;
  --header-height: ac(120px, 88px);

  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
/* @custom-media --mobile-menu-start-point (max-width: 1200px); */

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
/* @custom-media --mobile-menu-end-point (min-width: 1201px); */
