/* ../../assets/ */

@font-face {
  font-family: 'icomoon';
  src:  url('../../assets/fonts/icomoon.eot?ww8db1');
  src:  url('../../assets/fonts/icomoon.eot?ww8db1#iefix') format('embedded-opentype'),
  url('../../assets/fonts/icomoon.ttf?ww8db1') format('truetype'),
  url('../../assets/fonts/icomoon.woff?ww8db1') format('woff'),
  url('../../assets/fonts/icomoon.svg?ww8db1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-chevron:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-left:before {
  content: "\e902";
}
.icon-arrow-next:before {
  content: "\e903";
}
.icon-arrow-prev:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-arrow-up:before {
  content: "\e906";
}
.icon-check:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e908";
}
.icon-developer:before {
  content: "\e909";
}
.icon-instagram:before {
  content: "\e90a";
}
.icon-linkedin:before {
  content: "\e90b";
}
.icon-location:before {
  content: "\e90c";
}
.icon-mail:before {
  content: "\e90d";
}
.icon-mail-second:before {
  content: "\e90e";
}
.icon-minus:before {
  content: "\e90f";
}
.icon-phone-second:before {
  content: "\e910";
}
.icon-plus:before {
  content: "\e911";
}
.icon-price:before {
  content: "\e912";
}
.icon-search:before {
  content: "\e913";
}
.icon-twitter:before {
  content: "\e914";
}

