.know-us {
  padding: ac(108px, 72px) 0;
  &__wrap {
    display: flex;
    justify-content: space-between;
    gap: ac(92px, 24px);
    @media (max-width: 860px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &__title {
    font-weight: 600;
    font-size: ac(56px, 38px);
    line-height: 1.12;
    letter-spacing: 0.01em;
    color: var(--cl-black);
    /* min-width: ac(390px, 265px); */
    text-wrap: nowrap;
  }
  &__content {
    max-width: ac(704px, 640px);
    font-weight: 400;
    font-size: ac(24px, 18px);
    line-height: 1.44;
    color: var(--cl-black);
    @media (max-width: 860px) {
      max-width: none;
    }
    p {
      &:not(:last-child) {
        margin-bottom: ac(16px, 10px);
      }
    }
  }
  &__btn {
    margin-top: ac(48px, 24px);
    width: fit-content;
    border: 1px solid var(--cl-black);
  }
}