:root {
  --pp-main: #707070;
  --pp-main-rgb: 112 112 112;

  --pp-black: #000;
  --pp-black-rgb: 0 0 0;

  --pp-white: #ffffff;
  --pp-white-rgb: 255 255 255;

  --pp-dark-blue: #0e1127;
  --pp-dark-blue-rgb: 14 17 39;

  --pp-blue: #3ab4ff;
  --pp-blue-rgb: 58 180 255;

  --pp-dark-gray: #343434;
  --pp-dark-gray-rgb: 52 52 52;

  --pp-light-gray: #dce1e4;
  --pp-light-gray-rgb: 220 225 228;

  --pp-font-main: var(--font-main);
}

.popup {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 8999;
  display: none;

  &.active {
    display: block;
  }

  &__close-bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: -1;
    transition: all 0.5s ease;
    animation-duration: 0.3s;
    animation-name: opacityPopupBg;
  }

  &__block {
    --pp-default-translate-x-from: -50%;
    --pp-default-translate-x-to: -50%;

    --pp-default-translate-y-from: -50%;
    --pp-default-translate-y-to: -50%;

    --pp-opacity-from: 0;
    --pp-opacity-to: 1;

    background: var(--pp-white);

    position: absolute;
    top: 50%;
    left: 50%;

    width: 90%;
    max-width: ac(850px, 600px);
    padding: ac(40px, 30px) ac(40px, 24px);

    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    transition: all 300ms ease-out;
    animation-duration: 0.6s;

    &.without-opacity {
      --pp-opacity-from: 1;
    }

    &.pos-l,
    &.pos-r {
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;
    }

    &.pos-t,
    &.pos-b {
      --pp-default-translate-y-from: 0;
      --pp-default-translate-y-to: 0;
    }

    &.pos-t {
      top: 0;
      bottom: initial;
    }

    &.pos-r {
      right: 0;
      left: initial;
    }

    &.pos-b {
      top: initial;
      bottom: 0;
    }

    &.pos-l {
      right: initial;
      left: 0;
    }

    &.full-height {
      max-height: 100vh;
      height: 100%;
      right: 0;
      left: initial;
      --pp-default-translate-x-from: 0;
      --pp-default-translate-x-to: 0;

      .popup__content {
        max-height: calc(var(--vh, 1vh) * 72);
      }
    }

    &.slide-l {
      --pp-default-translate-x-from: 100%;
    }

    &.slide-r {
      --pp-default-translate-x-from: -100%;
    }

    &.slide-t {
      --pp-default-translate-y-from: -100%;
    }

    &.slide-b {
      --pp-default-translate-y-from: 100%;
    }

    &.slide-t,
    &.slide-r,
    &.slide-b,
    &.slide-l {
      animation-name: popupSlide;
    }
  }

  &__title {
    color: var(--pp-dark-blue);
    font-size: ac(24px, 20px);
    line-height: 1.2;
    font-family: var(--pp-font-main);
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: ac(32px, 24px);
  }

  &__btn-close {
    position: absolute;
    right: ac(25px, 15px);
    top: ac(25px, 15px);
    transition: all 300ms ease-out;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .close-clip {
      transition: inherit;
      width: ac(24px, 20px);
      height: ac(24px, 20px);
      background: var(--pp-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    i {
      transition: inherit;
      font-size: ac(24px, 20px);
      color: var(--pp-dark-blue);
    }

    &:hover {
      .close-clip {
        background: var(--pp-blue);
        transform: scale(1.05);
      }

      i {
        color: var(--pp-blue);
        transform: scale(1.05);
      }
    }
  }

  &__content {
    max-height: calc(var(--vh, 1vh) * 66);
    overflow: auto;
    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;
      transform: translateX(10px);

      .simplebar-scrollbar {
        background: var(--pp-dark-blue);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    .popup-form {
      &__container {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 15px;
        grid-row-gap: 15px;

        @mixin max-sm {
          grid-template-columns: repeat(1, minmax(0, 1fr));
        }
      }

      &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;
        padding-top: ac(24px, 20px);
        flex-wrap: wrap;

        .popup-btn {
          margin-left: auto;
        }
      }
    }

    .popup-btn {
      font-size: ac(16px, 14px);
      font-weight: 700;
      font-family: var(--pp-font-main);
      line-height: 1.2;
      text-transform: uppercase;
      background-color: var(--pp-dark-blue);
      color: var(--pp-white);
      border: none;
      outline: none;
      border-radius: 4px;
      padding: 12px 18px;
      cursor: pointer;
      transition: 0.3s ease-in-out all;
      position: relative;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: skew(45deg);
        pointer-events: none;
      }

      &:hover {
        &::after {
          background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 40%,
            rgba(255, 255, 255, 0.7) 100%
          );
          animation: gradientPopupBtn 0.3s linear forwards;
        }
      }

      &:active {
        transform: scale(0.9);
        opacity: 0.9;
      }
    }
  }

  &__block {
    &.project-popup {
      padding: ac(48px, 24px) ac(48px, 20px);
      background: linear-gradient(
        57.88deg,
        rgba(174, 214, 204, 0.96) 17.35%,
        #eeeceb 76.35%
      );
      max-width: ac(636px, 600px);
      width: 95%;

      border: 1px solid rgba(5, 5, 5, 0.15);
      border-radius: ac(24px, 16px);
      scrollbar-width: thin;
      scrollbar-color: var(--cl-green) transparent;
      @media (max-width: 1024px) {
        overflow: auto;
      }
      .popup-form {
        @media (max-width: 1024px) {
          padding-bottom: 14px;
        }
      }
      .success-title {
        font-size: ac(32px, 24px);
        width: 100%;
        grid-column: 1 / -1;
        text-align: left;
        color: var(--cl-black);
      }

      .popup {
        &__content {
          width: calc(100% + 12xp);
          padding-right: 12px;

          .simplebar-track.simplebar-vertical {
            width: 3px;
            background: #b0d8ce;
            border-radius: 4px;
            transform: none;
            top: ac(86px, 40px);
            bottom: 0;
            overflow: visible;

            .simplebar-scrollbar {
              background: #b3b3b3;
              border-radius: 4px;
              width: 6px;
              left: -1.5px;

              &:before {
                content: none;
              }
            }
          }
        }

        &__title {
          font-size: ac(48px, 24px);
          font-weight: 600;
          line-height: 1.12;
          letter-spacing: 0.02em;
          color: var(--cl-black);
          text-transform: none;

          &:not(:last-child) {
            margin-bottom: ac(32px, 20px);
          }
        }

        &__btn-close {
          position: absolute;
          top: ac(32px, 16px);
          right: ac(32px, 16px);
          display: flex;
          justify-content: center;
          align-items: center;
          --size: ac(32px, 28px);
          width: var(--size);
          height: var(--size);
          cursor: pointer;
          z-index: 10;

          i {
            color: var(--cl-black);
            font-size: ac(32px, 28px);
            transition: 0.3s;
          }

          &:hover {
            i {
              color: var(--cl-purple);
              transition: 0.3s;
            }
          }
        }
      }

      .popup-form {
        &__container {
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-column-gap: ac(16px, 14px);
          grid-row-gap: 12px;

          @media (max-width: 551px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }
        }

        &__bottom {
          padding-top: ac(32px, 20px);
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 16px;

          .meta-checkbox {
            max-width: calc(100% - 192px);
          }

          .btn {
            min-width: 192px;
            background: var(--cl-black);
            border: 1px solid var(--cl-black);

            &:after {
              display: none;
            }

            &:hover {
              border-color: var(--cl-black);
              background-color: transparent;
              color: var(--cl-black);
            }
          }

          @media (max-width: 551px) {
            flex-direction: column;

            .meta-checkbox {
              max-width: 100%;
              width: 100%;
            }

            .btn {
              width: 100%;
            }
          }
        }
      }
    }

    &.team-popup {
      padding: 0;
      background: linear-gradient(
        57.88deg,
        rgba(174, 214, 204, 0.96) 17.35%,
        #eeeceb 76.35%
      );
      max-width: ac(1014px, 900px);
      width: 95%;
      border-radius: ac(16px, 10px);
      border: 1px solid rgba(5, 5, 5, 0.1);
      overflow: hidden;

      .team-popup {
        &__container {
          width: 100%;
          display: flex;
          align-items: stretch;
          justify-content: flex-start;
        }

        &__content-container-decor {
          --arw: 411.2;
          --arh: 448.96;
          position: absolute;
          bottom: ac(-102px, -40px);
          right: ac(-111px, -40px);
          width: ac(411px, 190px);
          mix-blend-mode: soft-light;
        }

        &__card {
          width: ac(335px, 300px);
          min-width: ac(335px, 300px);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          background: var(--cl-white);
          border-right: 1px solid rgba(5, 5, 5, 0.5);

          @media (max-width: 640px) {
            display: none;
          }
        }

        &__card-image-container {
          width: 100%;
          overflow: hidden;
          padding-top: ac(19px, 16px);
          position: relative;
          background: var(--cl-aqua);
          overflow: hidden;
        }

        &__card-image-decor {
          --arw: 334.68;
          --arh: 424.36;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
        }

        &__card-image {
          --arw: 335;
          --arh: 316;
          width: 100%;
          position: relative;
          z-index: 1;

          img {
            object-fit: contain;
            object-position: bottom;
          }
        }

        &__card-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: ac(24px, 16px) ac(32px, 20px) ac(24px, 20px);
          background: white;
          flex: 1;
        }

        &__card-title {
          font-size: ac(32px, 24px);
          font-weight: 600;
          line-height: 1.12;
          letter-spacing: 0.02em;
          color: var(--cl-black);

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }

        &__card-subtitle {
          font-size: ac(17px, 16px);
          font-weight: 600;
          line-height: 1.44;
          color: #9378c8;

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }

        &__card-socials {
          margin-top: auto;
          grid-column-gap: ac(8px, 6px);
          display: flex;
          align-items: center;
          justify-content: flex-start;
          width: calc(100% - 158px);
        }

        &__card-social {
          font-size: ac(40px, 36px);
          color: var(--cl-black);
          transition: 0.3s;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            color: var(--cl-purple);
          }
        }

        &__content-container {
          width: calc(100% - ac(335px, 300px));
          position: relative;

          padding-right: ac(96px, 40px);
          padding-top: ac(48px, 24px);
          padding-left: ac(48px, 20px);
          padding-bottom: 0;

          @media (min-width: 641px) {
            .team-popup__card-image-container {
              display: none;
            }

            .team-popup__card-content {
              display: none;
            }
          }

          @media (max-width: 640px) {
            width: 100%;

            .team-popup__card-image-container {
              max-width: 390px;
              width: calc(100% - 24px);
              background: transparent;
            }

            .team-popup__card-content {
              border-top: 1px solid rgba(5, 5, 5, 0.1);
              padding-left: 0;
              padding-right: 0;
              background: transparent;
            }
          }
        }

        &__content {
          width: calc(100% + ac(48px, 21px));
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding-right: ac(48px, 21px);
          overflow: visible;

          .simplebar-track.simplebar-vertical {
            width: 3px;
            background: #b0d8ce;
            border-radius: 4px;
            transform: none;
            top: ac(72px, 60px);
            bottom: ac(40px, 20px);
            overflow: visible;

            .simplebar-scrollbar {
              background: #b3b3b3;
              border-radius: 4px;
              width: 6px;
              left: -1.5px;

              &:before {
                content: none;
              }
            }
          }

          @media (min-width: 641px) {
            max-height: 55.44vh;
          }
        }

        &__title {
          font-size: ac(44px, 32px);
          font-weight: 600;
          line-height: 1.12;
          color: var(--cl-black);

          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }

        &__text {
          width: 100%;
          font-size: ac(20px, 18px);
          font-weight: 400;
          line-height: 1.48;
          font-family: var(--font-fourth);
          color: var(--cl-black);

          p,
          ul,
          ol {
            &:not(:last-child) {
              margin-bottom: ac(20px, 14px);
            }

            &:last-child {
              padding-bottom: ac(24px, 20px);
            }
          }
        }

        &__btn-close {
          position: absolute;
          top: ac(32px, 16px);
          right: ac(32px, 16px);
          display: flex;
          justify-content: center;
          align-items: center;
          --size: ac(32px, 28px);
          width: var(--size);
          height: var(--size);
          cursor: pointer;
          z-index: 10;

          i {
            color: var(--cl-black);
            font-size: ac(32px, 28px);
            transition: 0.3s;
          }

          &:hover {
            i {
              color: var(--cl-purple);
              transition: 0.3s;
            }
          }
        }
      }
    }
  }
}

@keyframes popupSlide {
  from {
    transform: translateY(var(--pp-default-translate-y-from))
      translateX(var(--pp-default-translate-x-from));
    opacity: var(--pp-opacity-from);
  }

  to {
    transform: translateY(var(--pp-default-translate-y-to))
      translateX(var(--pp-default-translate-x-to));
    opacity: var(--pp-opacity-to);
  }
}

@keyframes opacityPopupBg {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes gradientPopupBtn {
  0% {
    transform: translateX(-100%) skew(45deg);
  }

  100% {
    transform: translateX(100%) skew(45deg);
  }
}
